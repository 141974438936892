import { Route, Routes, useSearchParams } from "react-router-dom";
import "./App.css";
import Main from "../Main/Main";
import Price from "../Price/Price";
import Partners from "../Partners/Partners";
import AboutUs from "../AboutUs/AboutUs";
import Blog from "../Blog/Blog";
import { useEffect, useState } from "react";
import {
  FOOTER_LINKS_COLUMN,
  initialSignupValues,
  initialSugnupValidity,
  productIdStorageKey,
} from "../../assets/utils/constants";
import Header from "../Header/Header";
import { useTheme } from "../../assets/hooks/use-theme";
import { ThemeContext } from "../../assets/contexts/themeContext";
import Footer from "../Main/Footer/Footer";
import validator from "validator";
import SignUp from "../Auth/SignUp/SignUp";
import Article from "../Blog/Article/Article";

function App() {
  const { theme, setTheme } = useTheme();
  const [searchParams] = useSearchParams();
  if (!localStorage.getItem('referrer')) {
    const referrer = document.referrer;
    if (referrer) localStorage.setItem('referrer', referrer)
  }

  const value = searchParams.get("value");
  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState(undefined);
  const [searchKey, setSearchKey] = useState("");
  const [isSignupPopupOpen, setIsSignupPopupOpen] = useState(false);
  const [signupValues, setSignupValues] = useState(initialSignupValues);
  const [signupValidity, setSignupValidity] = useState(initialSugnupValidity);
  useEffect(() => {
    let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];
    localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
  }, [])

  useEffect(() => {
    if (!value) return;

    const products = [
      ...FOOTER_LINKS_COLUMN,
      // ...CARDS_PROMO,
      // ...CARDS_POPULAR,
      // ...CARDS_SUB,
      // EXAMPLE_CARD.data,
    ];
    console.log(products);
    const filtered_products = products.filter(
      (item) =>
        item.title.toLowerCase().includes(value.toLowerCase()) ||
        item.title.toLowerCase().includes(searchKey.toLowerCase())
    );
    setItems(filtered_products);
  }, [value, searchKey]);

  function handleSignupPopupOpen() {
    setIsSignupPopupOpen(true);
  }

  function handleSignupPopupClose() {
    setIsSignupPopupOpen(false);
    setSignupValues(initialSignupValues);
    setSignupValidity(initialSugnupValidity);
  }

  function handleSignupValuesChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case "email": {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        if (!value) {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: "",
                validState: true,
              },
            }));
          } else {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !evt.target.validity.valid
                  ? evt.target.validationMessage
                  : "Invalid email",
                validState: false,
              },
            }));
          }
        }
        break;
      }

      case "code": {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        break;
      }


      case "password": {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        setSignupValidity((prevVal) => ({
          ...prevVal,
          [name]: {
            errorMessage: "",
            validState: value.length >= 8,
          },
        }));
        break;
      }


      case "phone": {
        let inputValue = value.replace(/\D/g, "");
        setSignupValues((prevValue) => ({
          ...prevValue,
          [name]: inputValue,
        }));

        // if (inputValue.match(/^(?:50|51|52|55|56|58|2|3|4|6|7|9)\d{7}$/g)) {4
        if (inputValue.length >= 8) {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: true,
            },
          }));
        } else {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        break;
      }

      default: {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        setSignupValidity((prevVal) => ({
          ...prevVal,
          [name]: {
            errorMessage: "",
            validState: Boolean(value),
          },
        }));
      }
    }
  }

  
  return (
    <div className="app">
      <ThemeContext.Provider value={{ setTheme, theme }}>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <Main
                theme={theme}
                onSignupOpen={handleSignupPopupOpen}
                email={signupValues.email}
                emailValidity={signupValidity.email}
                onEmailChange={handleSignupValuesChange}
              />
            }
          />
          <Route
            path="/prices"
            element={
              <Price theme={theme} onSignupOpen={handleSignupPopupOpen} />
            }
          />
          <Route path="/about" element={<AboutUs />} />
          <Route
            path="/blog"
            element={
              <Blog theme={theme} searchValue={value} {...{ searchKey, setSearchKey }} />
            }
          />
          <Route
            path="/blog/post/:translit_name"
            element={<Article onSignupOpen={handleSignupPopupOpen} />}
          />
          <Route path="/partners" element={<Partners />} />
        </Routes>
        <Footer onSignupOpen={handleSignupPopupOpen} />
        <SignUp
          isOpen={isSignupPopupOpen}
          onClose={handleSignupPopupClose}
          values={signupValues}
          valuesValidity={signupValidity}
          onChange={handleSignupValuesChange}
        />
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
